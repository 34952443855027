<template>
  <a-card :bordered="false">
    <template slot="title">
      <a-row align="middle" justify="space-between" type="flex">
        <a-col :span="4">
          <span style="font-weight: bolder; font-size: 20px;">各街道采集情况概览</span>
        </a-col>
        <a-col :span="2">
          <download-excel
              :data="data"
              :fields="jsonFields"
              name="各街道采集情况概览.xls"
              type="xls"
              worksheet="各街道采集情况概览">
            <a-button type="primary">下载该表</a-button>
          </download-excel>
        </a-col>
      </a-row>
    </template>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination" >
      <span slot="kitchenWasteWeight" slot-scope="text">{{ text }}KG</span>
      <span slot="otherWasteWeight" slot-scope="text">{{ text }}KG</span>
      <span slot="recyclableWasteWeight" slot-scope="text">{{ text }}KG</span>
      <span slot="harmfulWasteWeight" slot-scope="text">{{ text }}KG</span>
      <span slot="kitchenWasteRate" slot-scope="text">{{ text }}%</span>
      <span slot="otherWasteRate" slot-scope="text">{{ text }}%</span>

      <!-- <span slot="action" slot-scope="text, record">
        <a-button v-permission="'detail'"
                  type="primary"
                  @click="$notification['warning']({ message: '敬请期待'})">详情</a-button>
      </span> -->
    </a-table>
  </a-card>
</template>

<script>
import {queryOverviewOfStreetCollect} from "@/api/home";
import _ from 'lodash'
import {trashType} from "@/common/config";
import {fromNumberToPercentage, parseNumberWith2Fixed} from "@/utils/transition";
import {formatToDate, formatToDateBegin, formatToDateEnd, getPreviewDate} from "@/utils/time";
import {queryUserInfo} from "@/api/user";
import {hasPermission} from "@/utils/permission";

export default {
  name: "overviewOfStreetCollect",
  data() {
    return {
      hasPermission,
      columns: [
        {
          title: '街道',
          dataIndex: 'streetName',
          key: 'streetName',
          align: 'center',
        },
        {
          title: '接入小区',
          dataIndex: 'totalCenter',
          key: 'totalCenter',
          align: 'center',
        },
        {
          title: '接入小区(无数据)',
          dataIndex: 'noDataCenter',
          key: 'noDataCenter',
          align: 'center',
        },
        {
          title: '厨余垃圾',
          dataIndex: 'kitchenWasteWeight',
          key: 'kitchenWasteWeight',
          scopedSlots: {customRender: 'kitchenWasteWeight'},
          align: 'center',
          sorter: (a, b) => a.kitchenWasteWeight - b.kitchenWasteWeight,
        },
        {
          title: '其他垃圾',
          dataIndex: 'otherWasteWeight',
          key: 'otherWasteWeight',
          scopedSlots: {customRender: 'otherWasteWeight'},
          align: 'center',
          sorter: (a, b) => a.otherWasteWeight - b.otherWasteWeight,
        },
        {
          title: '可回收物',
          key: 'recyclableWasteWeight',
          dataIndex: 'recyclableWasteWeight',
          scopedSlots: {customRender: 'recyclableWasteWeight'},
          align: 'center',
          sorter: (a, b) => a.recyclableWasteWeight - b.recyclableWasteWeight,
        },
        {
          title: '有害垃圾',
          key: 'harmfulWasteWeight',
          dataIndex: 'harmfulWasteWeight',
          scopedSlots: {customRender: 'harmfulWasteWeight'},
          align: 'center',
          sorter: (a, b) => a.harmfulWasteWeight - b.harmfulWasteWeight,
        },
        {
          title: '厨余垃圾分出率',
          key: 'kitchenWasteRate',
          dataIndex: 'kitchenWasteRate',
          scopedSlots: {customRender: 'kitchenWasteRate'},
          align: 'center',
          sorter: (a, b) => a.kitchenWasteRate - b.kitchenWasteRate,
        },
        {
          title: '其他垃圾分出率',
          key: 'otherWasteRate',
          dataIndex: 'otherWasteRate',
          scopedSlots: {customRender: 'otherWasteRate'},
          align: 'center',
          sorter: (a, b) => a.otherWasteRate - b.otherWasteRate,
        },
        // {
        //   title: '查看',
        //   key: 'action',
        //   scopedSlots: {customRender: 'action'},
        //   align: 'center'
        // },
      ],
      data: [],
      pagination: {
        pageSize: 6,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      loading: true,
      jsonFields: {},
      hash: {}
    }
  },
  mounted() {
    this.generateJsonFields()
  },
  methods: {
    async queryStreetCollectList(query) {
      this.loading = true

      const data = await queryOverviewOfStreetCollect({
        beginDate: formatToDate(query.time[0]),
        endDate: formatToDate(query.time[1]),
      })

      this.data = _.map(data, (value, key) => {
        let tmp = {
          key: key,
          streetName: value.streetName,
          totalCenter: value.totalCenter,
          noDataCenter: value.noDataCenter
        }
        _.forEach(trashType, (trash) => {
          tmp[`${trash.eng}Weight`] = parseNumberWith2Fixed(value[`${trash.eng}Sum`])
          tmp[`${trash.eng}Rate`] = fromNumberToPercentage(value[`${trash.eng}SumPercent`])
        })
        return tmp
      })

      this.loading = false
    },

    generateJsonFields() {
      this.jsonFields = {}
      _.forEach(this.columns, (value) => {
        if (value.dataIndex) {
          this.jsonFields[value.title] = {
            field: value.dataIndex
          }

          if (_.endsWith(value.dataIndex, 'Weight')) {
            this.jsonFields[value.title].callback = (value) => {
              return `${value}KG`;
            }
          }
          if (_.endsWith(value.dataIndex, 'Rate')) {
            this.jsonFields[value.title].callback = (value) => {
              return `${value}%`;
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
