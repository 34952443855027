<template>
  <div class="content">
    <a-space direction="vertical" style="width: 100%">
      <a-card>
        <a-row>
          <a-form layout="inline">
            <a-form-item label="查询时间">
              <a-range-picker v-model="query.time" @change="handleQueryDateRangeChange"/>
            </a-form-item>
          </a-form>
        </a-row>

        <a-row :gutter="16" style="padding-top: 24px;">
          <a-spin :spinning="loading">
            <a-col v-for="statistic in trashCollectAmountList" :key="statistic.head" :span="6">
              <trash-collect-amount-card v-bind="statistic" :loading="loading"/>
            </a-col>
          </a-spin>
        </a-row>

        <a-row style="padding-top: 24px;">
          <overview-of-street-collect ref="overviewOfStreetCollect"/>
        </a-row>
      </a-card>

      <!-- <a-card>
        <a-row :gutter="16">
          <a-col :span="6">
            <community-join ref="communityJoin"/>
          </a-col>

          <a-col :span="18">
            <data-alarm ref="dataAlarm"
                        @changeQuery="(query)=>{$refs.communityJoin.query=query; $refs.communityJoin.customQuery();}"/>
          </a-col>
        </a-row>
      </a-card>

      <a-row :gutter="16">
        <a-col :span="12">
          <heat-map/>
        </a-col>

        <a-col :span="12">
          <curve-line/>
        </a-col>
      </a-row> -->
    </a-space>
  </div>
</template>
<script>
import TrashCollectAmountCard from './component/trashCollectAmountCard'
import OverviewOfStreetCollect from './component/overviewOfStreetCollect'
import CommunityJoin from './component/communityJoin'
import DataAlarm from './component/dataAlarm'
import HeatMap from './component/heatmap'
import CurveLine from './component/curveLine'
import {queryTrashCollectSum} from "@/api/home";
import moment from "moment";
import _ from "lodash"
import {trashType} from "@/common/config";
import {fromNumberToPercentage, parseNumberWith2Fixed} from "@/utils/transition";
import {getPreviewDate, formatToDateBegin, formatToDateEnd, formatToDate} from "@/utils/time";

export default {
  components: {
    TrashCollectAmountCard, OverviewOfStreetCollect, CommunityJoin, DataAlarm, HeatMap, CurveLine
  },
  data() {
    return {
      query: {
        time: []
      },
      trashCollectAmountList: [],
      loading: true,
    };
  },
  mounted() {
    this.trashCollectAmountList = _.map(trashType, value => {
      return {
        type: value.type,
        headCount: 0,
        subheadCount: fromNumberToPercentage(0),
      }
    })

    this.query.time = getPreviewDate(1, 1)

    this.queryTrashCollectAmountList(this.query)
    this.$refs.overviewOfStreetCollect.queryStreetCollectList(this.query)
    // this.$refs.communityJoin.queryCommunityJoinCondition(this.query)
    // this.$refs.dataAlarm.queryDataAlarmList(this.query)
  },
  methods: {
    handleQueryDateRangeChange(dates) {
      this.queryTrashCollectAmountList(this.query)
      this.$refs.overviewOfStreetCollect.queryStreetCollectList(this.query)
    },

    async queryTrashCollectAmountList(query) {
      this.loading = true

      const data = await queryTrashCollectSum({
        beginDate: formatToDate(query.time[0]),
        endDate: formatToDate(query.time[1]),
      })

      this.trashCollectAmountList = _.map(trashType, (value, key) => {
        return {
          type: parseInt(key),
          headCount: parseNumberWith2Fixed(data[`${value.eng}Sum`]),
          subheadCount: fromNumberToPercentage(data[`${value.eng}SumPercent`]),
        }
      })

      this.loading = false
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
