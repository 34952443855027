<template>
  <a-card :bordered="false" class="statistic-card">
    <a-row>
      <a-col :span="8">
        <img alt="" class="icon" src="../../../assets/icons/统计中心.png"/>
      </a-col>
      <a-col :span="16">
        <p class="head">{{ trashType[type].name }}采集总量</p>
        <p class="head-count">{{ headCount }}KG</p>
        <p class="subhead">{{ trashType[type].name }}分出率：<span class="subhead-count">{{ subheadCount }}%</span></p>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import {trashType} from '@/common/config'

export default {
  name: "trashCollectAmountCard",
  props: {
    type: {type: Number, required: true},
    headCount: {type: Number, required: true, default: 0},
    subheadCount: {type: Number, required: true, default: 0},
    loading: {type: Boolean, required: false, default: true},
  },
  data() {
    return {
      trashType
    }
  },
}
</script>

<style lang="scss" scoped>
.statistic-card {
  box-shadow: rgb(240, 242, 245) 2px 2px 5px 1px;
  //height: 120px;

  .icon {
    height: 80px;

  }

  .head {
    font-weight: bolder;
    font-size: larger;
    margin-bottom: 4px;
  }

  .head-count {
    font-size: larger;
    margin-bottom: 8px;
  }

  .subhead {
    margin-bottom: 0;

    .subhead-count {
      color: red;
    }
  }
}
</style>
